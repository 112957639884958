import { render, staticRenderFns } from "./BaseContextMenu.vue?vue&type=template&id=73a2388a&scoped=true"
import script from "./BaseContextMenu.vue?vue&type=script&lang=js"
export * from "./BaseContextMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73a2388a",
  null
  
)

export default component.exports