<template>
  <v-menu
    v-model="show"
    :position-x="contextMenuX"
    :position-y="contextMenuY"
    absolute
    offset-y
  >
    <v-list dense>
      <template v-for="(item, index) in contextMenuItems">
        <v-list-item
          :key="index"
          @click="performAction(item)"
          v-show="item.visible"
          :disabled="!item.enabled"
        >
          <v-list-item-icon>
            <v-icon :color="menuItemColorClass" :disabled="!item.enabled">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { eventNames } from "@/model/common/events/eventConst";
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { userMixin } from "@/mixins/shared/user/userMixin";

export default {
  name: "BaseContextMenu",
  mixins: [menuItemSettingsMixin, userMixin],
  props: {
    contextMenuItems: {
      type: Array,
      required: false
    },
    rightClickedItem: undefined
  },

  data() {
    return {
      show: false,
      contextMenuX: 0,
      contextMenuY: 0
    };
  },

  methods: {
    /**
     * closes context menu
     */
    close() {
      this.show = false;
      this.contextMenuX = 0;
      this.contextMenuY = 0;
    },

    /**
     * Opens Context Menu
     * @param event
     */
    open(event) {
      // updates position of context menu
      this.show = true;
      this.contextMenuX = event.clientX;
      this.contextMenuY = event.clientY;
    },

    /**
     * Opens Context Menu using Touch
     * @param event
     */
    openTouch(event) {
      // updates position of context menu using Touch
      this.show = true;
      this.contextMenuX = event.touches[0].clientX;
      this.contextMenuY = event.touches[0].clientY;
    },

    /**
     * Perform Context Menu Action
     * @param action
     */
    performAction(action) {
      const payload = {
        id: this.rightClickedItem?.id ?? -1,
        action: action
      };
      this.$emit(eventNames.onContextAction, payload);
    }
  }
};
</script>

<style scoped></style>
